<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 6.51355C0 3.15766 3.13425 0.4375 7 0.4375C10.8658 0.4375 14 3.15766 14 6.51437C14 9.87027 10.8658 12.5904 7 12.5904C6.30175 12.5904 5.628 12.501 4.99187 12.3361L2.60837 13.5625V11.2451C1.01762 10.1311 0 8.4257 0 6.51355ZM5.91476 6.83799L7.69713 8.62053L11.5235 4.81263L8.08825 6.59517L6.26213 4.81263L2.43663 8.62053L5.91476 6.83799Z"
      :fill="`url(#${id})`"
    />
    <defs>
      <linearGradient
        :id="id"
        x1="0"
        y1="13.5625"
        x2="14.3507"
        y2="13.5625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5674FC" />
        <stop offset="1" stop-color="#FA6189" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup>
import uniqueId from 'lodash/uniqueId'

const id = ref(uniqueId('linear-gradient-'))
</script>
