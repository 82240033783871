<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0808 1.36454C11.7369 1.04449 10.347 0.0239254 7.25131 0.0100685C7.25131 0.0100685 3.60103 -0.212811 1.82145 1.43869C0.830971 2.44085 0.482436 3.90678 0.44555 5.72545C0.409086 7.54409 0.361173 10.951 3.60823 11.8752L3.61121 11.8761L3.60951 13.2858C3.60951 13.2858 3.58916 13.8569 3.96017 13.9729C4.40963 14.1141 4.67335 13.6801 5.10245 13.2125C5.33819 12.9561 5.66297 12.579 5.90807 12.2908C8.12903 12.4789 9.83694 12.0474 10.0307 11.9837C10.4793 11.8363 13.0166 11.5078 13.4292 8.10088C13.8549 4.58796 13.2227 2.3667 12.0808 1.36454ZM12.4645 7.84698C12.1156 10.6921 10.0583 10.8718 9.67922 10.995C9.51766 11.0473 8.01709 11.4249 6.13109 11.3L4.08654 13.5389L4.08442 10.9589L4.06831 10.9548C1.32156 10.1831 1.41398 7.28977 1.44494 5.77107C1.4759 4.25238 1.75787 3.00809 2.59613 2.17146C4.10096 0.79226 7.20173 0.998405 7.20173 0.998405C9.8204 1.01013 11.0755 1.80739 11.3663 2.07468C12.3322 2.91177 12.8245 4.91391 12.4645 7.84698Z"
      fill="#AA5ABE"
    />
    <path
      d="M10.1003 8.27139C10.099 8.27014 9.68393 7.92031 9.48123 7.76949C9.29001 7.62748 8.98515 7.43643 8.8147 7.33922C8.50898 7.16704 8.1965 7.27513 8.06758 7.44439L7.79962 7.78502C7.66225 7.95762 7.45067 7.9287 7.39512 7.9174C6.9991 7.81934 6.57806 7.57468 6.14723 7.16243C5.71138 6.74644 5.44762 6.31198 5.35351 5.86036L5.34672 5.83058C5.32126 5.64497 5.35265 5.5591 5.42939 5.43261L5.44381 5.42085C5.62486 5.25454 5.96028 5.05219 6.0052 4.85944C6.13706 4.52472 5.56466 3.82676 5.48282 3.70358C5.48282 3.70358 5.06645 3.15894 4.91848 3.05502C4.75861 2.92349 4.511 2.84848 4.26126 3.00016L4.24853 3.00936C3.53112 3.48905 3.27119 3.76473 3.342 4.17617L3.37888 4.34163C3.74268 5.47489 4.44526 6.68316 5.493 7.75904C6.52926 8.8215 7.65035 9.45787 8.75533 9.89862C9.04239 9.99288 9.34002 9.91159 9.64531 9.65396L9.65042 9.65016C9.88448 9.434 10.0698 9.20273 10.208 8.95512L10.2093 8.95012C10.3437 8.68826 10.2983 8.44022 10.1003 8.27139Z"
      fill="#AA5ABE"
    />
    <path
      d="M8.38017 5.78976C8.33648 5.78976 8.30002 5.75501 8.2979 5.7106C8.28348 5.41691 8.20336 5.19193 8.0596 5.04154C7.91674 4.89238 7.7026 4.80903 7.4236 4.79393C7.37822 4.7914 7.34305 4.75204 7.3456 4.70596C7.34812 4.65987 7.3867 4.6247 7.43251 4.62679C7.75474 4.64438 8.00534 4.74493 8.17834 4.92549C8.35047 5.10563 8.4463 5.36663 8.46284 5.70221C8.46496 5.7483 8.42976 5.78727 8.38442 5.78976H8.38017Z"
      fill="#AA5ABE"
    />
    <path
      d="M9.19766 6.067H9.19554C9.15016 6.06572 9.11413 6.0276 9.11499 5.98152C9.12769 5.41762 8.96105 4.94084 8.6193 4.5638C8.44929 4.37567 8.24277 4.22697 8.00491 4.12181C7.76664 4.01664 7.4889 3.95172 7.18023 3.92866C7.13485 3.92529 7.1005 3.88511 7.10389 3.83945C7.1073 3.79337 7.14671 3.75858 7.19209 3.76192C7.851 3.81095 8.37212 4.04261 8.74142 4.4511C9.11244 4.86044 9.29392 5.37697 9.28033 5.98532C9.27907 6.03094 9.24218 6.067 9.19766 6.067Z"
      fill="#AA5ABE"
    />
    <path
      d="M10.0347 6.39814C9.98937 6.39814 9.95251 6.3613 9.95208 6.31518C9.94827 5.81666 9.87236 5.36582 9.72565 4.97456C9.57976 4.58618 9.35973 4.24474 9.07141 3.95986C8.78266 3.67537 8.45446 3.45836 8.09574 3.31464C7.73618 3.17053 7.33549 3.09599 6.90471 3.09304C6.85933 3.09261 6.82243 3.05491 6.82286 3.00883C6.8233 2.96275 6.86019 2.92587 6.90557 2.92587H6.90597C7.8104 2.93258 8.57741 3.2401 9.18673 3.84045C9.49242 4.1421 9.72565 4.50365 9.87998 4.91548C10.0335 5.32478 10.1132 5.7957 10.1174 6.31394C10.1179 6.36002 10.081 6.39772 10.0356 6.39814H10.0347Z"
      fill="#AA5ABE"
    />
  </svg>
</template>
